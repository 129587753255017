import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../routes';

// Lazy load the pages
const Connect = React.lazy(() => import('@acin/data-matching').then((module) => ({ default: module.Connect })));
const ConnectClient = React.lazy(() =>
  import('@acin/data-matching').then((module) => ({ default: module.ConnectClient })),
);

const ConnectJobs = React.lazy(() => import('@acin/data-matching').then((module) => ({ default: module.ConnectJobs })));
const ConnectJob = React.lazy(() => import('@acin/data-matching').then((module) => ({ default: module.ConnectJob })));

const Cluster = React.lazy(() => import('@acin/data-matching').then((module) => ({ default: module.Cluster })));
const ControlMatching = React.lazy(() =>
  import('@acin/data-matching').then((module) => ({ default: module.ControlMatching })),
);

// File Storage and Uploader:
const ConnectFileStorage = React.lazy(() =>
  import('@acin/data-matching').then((module) => ({ default: module.ConnectFileStorage })),
);
const Uploader = React.lazy(() => import('@acin/data-matching').then((module) => ({ default: module.UploadRoute })));

export const PrototypeRoutes = () => {
  return (
    <Switch>
      <Route exact path={`${ROUTES.PROTOTYPE.path}/:id/jobs/:jobId/:clusterId/matching`}>
        <ControlMatching />
      </Route>
      <Route exact path={`${ROUTES.PROTOTYPE.path}/:id/jobs/:jobId/:clusterId`}>
        <Cluster />
      </Route>
      {/* Jobs */}
      <Route exact path={`${ROUTES.PROTOTYPE.path}/:id/jobs/:jobId`}>
        <ConnectJob />
      </Route>
      <Route exact path={`${ROUTES.PROTOTYPE.path}/:id/jobs`}>
        <ConnectJobs />
      </Route>
      <Route exact path={`${ROUTES.PROTOTYPE.path}/:id/uploader`}>
        <Uploader />
      </Route>
      {/* File Storage */}
      <Route exact path={`${ROUTES.PROTOTYPE.path}/:id/file-storage`}>
        <ConnectFileStorage />
      </Route>
      <Route exact path={`${ROUTES.PROTOTYPE.path}/:id/overview`}>
        <ConnectClient />
      </Route>
      <Route exact path={ROUTES.PROTOTYPE.path}>
        <Connect />
      </Route>
    </Switch>
  );
};
