import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectProvider } from '@acin/data-matching';
import { Loading } from '@acin/ui-core';
import { ROUTES } from './routes';
import ScrollToTop from './ScrollToTop';
import { ConnectRoutes } from './connect/ConnectRoutes';
import { PrototypeRoutes } from './prototype/PrototypeRoutes';

// Lazy load the pages
const Dashboard = React.lazy(() => import('../pages/Dashboard').then((module) => ({ default: module.Dashboard })));

const RouteNotFound = () => (
  <Route path="*">
    <div>Error 404! Page not found</div>
  </Route>
);

export const PrivateLayout = () => {
  return (
    <React.Suspense fallback={<Loading position="absolute" withOverlay />}>
      <ScrollToTop />
      <Switch>
        <Route exact path={ROUTES.DASHBOARD.path}>
          <Dashboard />
        </Route>

        <Route path={ROUTES.CONNECT.path}>
          <ConnectRoutes />
        </Route>

        <Route path={ROUTES.PROTOTYPE.path}>
          <ConnectProvider>
            <PrototypeRoutes />
          </ConnectProvider>
        </Route>

        <RouteNotFound />
      </Switch>
    </React.Suspense>
  );
};
