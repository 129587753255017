import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Auth } from './Auth';
import { Login } from './Login';
import { PrivateRoute } from './PrivateRoute';
import { PrivateLayout } from './PrivateLayout';

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        {/*
            After login the user will get redirected to the path "/auth"
            Auth component will check for any Auth0 Error 
            otherwise it will redirect to the dashboard 
        */}
        <Route path="/auth">
          <Auth />
        </Route>

        {/* PrivateRoute will prevent users not authenticated to go to all Private Layout */}
        <PrivateRoute path="*">
          <PrivateLayout />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};
