type RouteType = {
  [key: string]: {
    id: string;
    label: string;
    path: string;
  };
};
export const ROUTES: RouteType = {
  DASHBOARD: {
    id: 'dashboard',
    label: 'Dashboard',
    path: '/',
  },
  CONNECT: {
    id: 'connection',
    label: 'Connection',
    path: '/connection',
  },
  MANTAIN: {
    id: 'mantain',
    label: 'Mantain',
    path: '/mantain',
  },
  RISK_INTELLIGENCE: {
    id: 'risk-intelligence',
    label: 'Risk Intelligence',
    path: '/risk-intelligence',
  },

  // temporary route to keep the prototype available
  // while developing the real connect product
  PROTOTYPE: {
    id: 'proto',
    label: 'Prototype',
    path: '/prototype',
  },
};

export const getPathFromId = (id: string) => {
  const keyFound = Object.keys(ROUTES).find((routeKey: string) => ROUTES[routeKey].id === id);
  if (!keyFound) {
    return undefined;
  }
  return ROUTES[keyFound].path;
};
