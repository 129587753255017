import { Auth0ClientOptions, CacheLocation } from '@auth0/auth0-spa-js';

declare global {
  interface Window {
    Cypress: any;
  }
}

export const ORIGIN_URL_KEY = 'origin-url';
const path = window.location.pathname;
if (path !== '/auth' && path !== '/') {
  window.localStorage.setItem(ORIGIN_URL_KEY, path);
}

let redirect_uri = process.env.REACT_APP_AUTH_CALLBACK!;
const build_env = process.env.REACT_APP_BUILD_ENV!;

if (['dev', 'int', 'prod'].includes(build_env)) {
  const envValue = process.env.REACT_APP_DEPLOYMENT_ENV_NAME;
  const deploymentQueryStringService = envValue ? `?ds=${envValue}` : '';
  redirect_uri = `https://${window.location.hostname}${deploymentQueryStringService}`;
}

let cacheLocation: CacheLocation = `memory`;

if (window.Cypress && process.env.REACT_APP_CYPRESS_ENABLE === 'true') {
  cacheLocation = `localstorage`;
}

export const config: Auth0ClientOptions = {
  domain: process.env.REACT_APP_AUTH_DOMAIN!,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID!,
  redirect_uri,
  audience: process.env.REACT_APP_API_AUTH_AUDIENCE!,
  cacheLocation,
};
