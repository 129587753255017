import React from 'react';
import { useAuth } from '@acin/user-accounts';

export const Login = () => {
  const { isAuthenticated, loading, login, logout } = useAuth();

  React.useEffect(() => {
    if (!isAuthenticated && !loading) {
      login();
    } else if (isAuthenticated && !loading) {
      logout();
    }
  }, [login, logout, isAuthenticated, loading]);

  return null;
};
