import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { inflate } from 'graphql-deduplicator';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { typePolicies } from '@acin/data-matching';

import introspectionResult from './graphql/introspection-result.json';

export const getClient = async (auth0Client: Auth0Client) => {
  // Get the API URL from the env vars
  let API = process.env.REACT_APP_API_URL;

  // Define the GraphQL API endpoint
  const httpLink = new HttpLink({ uri: API });

  /* Set in-memory token to reduce async requests */
  let token: string;

  // Handle errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  // This function will lookup and return the bearer token for the
  // next bit of context to add it to the headers
  // @TODO apply this logic
  const withTokenLink = setContext(async () => {
    if (token) return { bearerToken: token };

    const bearerToken = await auth0Client.getTokenSilently();
    token = bearerToken;
    // get token from somewhere, and return the context here
    return { bearerToken };
  });

  // Apollo link to add the bearer token to the headers
  const authLink = setContext((_, { headers, bearerToken }) => {
    return {
      headers: {
        ...headers,
        ...(bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {}),
      },
    };
  });

  const inflateLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      return inflate(response);
    });
  });

  /* Create Apollo Link array to pass to Apollo Client */
  const link = ApolloLink.from([withTokenLink, authLink, errorLink, inflateLink, httpLink]);

  // Define the in memory cache
  const cache = new InMemoryCache({
    ...introspectionResult,
    typePolicies: {
      ...typePolicies,
    },
  });

  // Setup apollo client with the link and cache
  const client = new ApolloClient({
    link,
    cache,
  });

  // Need to disable cache? Commenting out persistCache below
  // Persist the cache to local storage
  // await persistCache({ cache, storage: window.localStorage });

  return client;
};
