import React from 'react';
import { MatchingProvider } from '@acin/data-matching';
import { Route, useParams } from 'react-router-dom';
import { ROUTES } from '../routes';

// Lazy load the pages
const Job = React.lazy(() => import('../../pages/Connection/Job').then((module) => ({ default: module.Job })));

const Controls = React.lazy(() =>
  import('../../pages/Connection/Controls').then((module) => ({ default: module.Controls })),
);

const Risks = React.lazy(() => import('../../pages/Connection/Risks').then((module) => ({ default: module.Risks })));

const MatchingScreen = React.lazy(() =>
  import('../../pages/Connection/MatchingScreen').then((module) => ({ default: module.MatchingScreen })),
);

export const JobRoutes = () => {
  const { id, jobId } = useParams<{ id: string; jobId: string }>();
  return (
    <MatchingProvider accountCode={id} jobId={jobId}>
      <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId/controls/matching-screen`}>
        <MatchingScreen />
      </Route>
      <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId/controls`}>
        <Controls />
      </Route>

      <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId/risks`}>
        <Risks />
      </Route>

      <Route exact path={`${ROUTES.CONNECT.path}/:id/jobs/:jobId`}>
        <Job />
      </Route>
    </MatchingProvider>
  );
};
