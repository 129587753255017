/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { PrivateRoute as UiCorePrivateRoute } from '@acin/ui-core';
import { NoAccessPage } from '@acin/terminal-onboarding';
import { useAuth, USER_ROLES } from '@acin/user-accounts';

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { isAuthenticated, loading, hasRole } = useAuth();
  const { children, ...rest } = props;

  if (loading) {
    return null;
  }

  const renderContent = () => {
    if (!hasRole([USER_ROLES.SUPER_ADMIN])) {
      return <Route {...rest} render={() => <NoAccessPage userName="" hasAccount={false} />} />;
    }
    return children;
  };

  return (
    // UiCorePrivateRoute will push user not authenticated to "/login"
    <UiCorePrivateRoute isAuthenticated={isAuthenticated} redirectPath="/login">
      {renderContent()}
    </UiCorePrivateRoute>
  );
};
