import { AuthUser } from '@acin/user-accounts';
import { datadogRum } from '@datadog/browser-rum';

export const onAuthenticated = (user: AuthUser) => {
  datadogRum.setUser({
    id: user.sub,
    email: user.email,
    name: user.name,
  });
};
