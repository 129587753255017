import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import createAuth0Client from '@auth0/auth0-spa-js';
import { Core, Toaster } from '@acin/ui-core';
import { AuthProvider } from '@acin/user-accounts';
import { config, datadogInit } from './auth0';
import { App } from './App';
import { onAuthenticated } from './auth0/onAuthenticated';
import { getClient } from './apollo';
import * as serviceWorker from './serviceWorker';

createAuth0Client(config).then((auth0) => {
  const renderApp = async () => {
    const client = await getClient(auth0);
    datadogInit();
    ReactDOM.render(
      <ApolloProvider client={client}>
        <AuthProvider authClient={auth0} onAuthenticated={onAuthenticated}>
          <Core>
            <App />
            <Toaster />
          </Core>
        </AuthProvider>
      </ApolloProvider>,
      document.getElementById('root'),
    );
  };
  renderApp();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
